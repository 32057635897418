/* You can add global styles to this file, and also import other style files */
// @import "~ag-grid-community/dist/styles/ag-grid.css";
// @import "~ag-grid-community/dist/styles/ag-theme-balham.css";

@import url("../src/assets/styles/ag-grid-community/dls-ag-grid-light.css");
// @import url("../node_modules/bootstrap/dist/css/bootstrap.css");
// @import url("../assets/styles/ag-grid-community/dls-ag-grid-dark.css");
// @import url("../node_modules/@fortawesome/fontawesome-free/css/all.css");
// @import 'ngx-toastr/toastr';
@import '../node_modules/ngx-toastr/toastr.css';

html,
body {
    height: 100%;
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 14px;
}

html .mat-icon {
  width:25px !important;
}

.app-content {
    padding: 20px;
}

main.login-bg{
    height: 100vh;
}

main.container{
    max-width: 1086px;
}

.mat-app-background {
    background-color: #39414d !important;
}

.float-right{
    float: right !important;
}

span.required{
    color: #dc3545;
}

.rounded-div{
    padding: 5px 20px 10px 15px;
    border: 1px solid rgb(206, 201, 201);
    border-radius: 15px;
    -moz-border-radius:15px;

}

.files-div{
    padding: 5px 20px 10px 15px;
    border: 1px solid rgb(206, 201, 201);
    border-radius: 15px;
    -moz-border-radius:15px;
    height: inherit;
}

.model-div{
    padding: 10px 20px 10px 15px;
    border: 0px solid rgb(206, 201, 201);
    border-radius: 15px;
    -moz-border-radius:15px;
}

.hline{
    margin:40px 0;
}

.explorer-colr{
    background: #f1f4fc;
}


.row-ht {
    padding-top: 5px;
 height: 45px;
}

.watermark {
    position: center;
    padding-top: 120px;
    bottom: 2px;
    right: 2px;
    opacity: 1;
    z-index: 99;
    color: grey;
  }

.dls-page-header-row, .dls-page-title, .dls-secondary-navigation, .dls-secondary-navigation-toolbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center !important;
    align-items: top !important;

}
.dls-page-title{
    font-size: 16px !important;
}

.noborder {
    border: none;
  }

.ag-theme-dls{
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-size: 12px !important;
    //font-weight: 700;
}

.ag-theme-dls .ag-header-cell {
    padding: 30 30 30 30 !important;
    border-right: 0px solid transparent !important;
    border-left: 0px solid #c7d0d8 !important;
    font-weight: 700;
}

.ag-theme-dls .ag-rtl .ag-cell, .ag-theme-dls .ag-ltr .ag-cell, .ag-theme-dls .ag-cell {
    padding: 0 8px !important;
    border-right: 0px solid transparent !important;
    border-left: 0px solid #c7d0d8 !important;
    //height: 105px !important;
}


.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
}

.mat-table th,
.mat-table td {
  padding-left: 24px !important;
}


.icon-button{
  background: none;
  padding: 0;
  border: 0;
  cursor: pointer;
}

.icon-button .icon {
  width: 24px;
}

mat-tree-node {
  padding-left: 2.6rem !important;
}


.element-detail-item {
  padding:2rem;
}

.element-detail-item-header {
  font-size: 12px;
  font-weight: 500;
  color:#4c97cb;
}

.tab-icon {
  margin-right: 8px;
}


.mat-chip.mat-standard-chip {
  background-color: #e0e0e0 !important;
  color: #000000de !important;
}

.mat-chip {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.mat-standard-chip {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  display: inline-flex !important;
  padding: 7px 12px !important;
  border-radius: 16px !important;
  align-items: center !important;
  cursor: default !important;
  min-height: 32px !important;
  height: 1px !important;
}



